
$darkBg: #D4D4D4;
$darkTxt: #1c1c1c;
$lightBg: #E4E4E4;
$accent: #B61cc5;
$darkAccent: #611f69;

$breakPointSmall: 375px;
$breakPointMedium: 768px;
$breakPointLarge: 1024px;

html{background-color:$accent;}
*{font-family: 'Raleway', sans-serif; }
h1 {font-size: 2.5rem; color: $accent; font-family: 'Bitter', sans-serif; font-weight:500; text-align: left; margin-left:1rem; margin-bottom:1rem;}
h3.intro {
  font-size: 1.5rem;
    text-align: left;
    max-width: 65%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color:$accent;
    margin-left:.5rem;

  @media screen and (max-width: $breakPointLarge) {
    max-width:75%; 
    font-size: 2.5rem; 
  }
  @media screen and (max-width: $breakPointMedium) {
    max-width:85%; 
    font-size:2rem;
  }
  @media screen and (max-width: $breakPointSmall) {
    max-width:85%; 
    font-size:1.5rem;
  }

}
p{  margin-block-start: .75rem; margin-block-end: .75rem; line-height:1.6rem; font-size:1.125rem;}
p.dark {font-size:12px; color: $darkTxt}
p.light {font-size:12px; color: $lightBg}
p.identity {font-size:2rem; color: #1C1C1C; font-family: 'Bitter', sans-serif; font-weight:500; width: calc(100% + 2rem); margin-top:2rem; line-height:2.2rem;}
p.imageCaption { font-size:42px; color: white; font-family: 'Abril Fatface', cursive; text-align:left; position: relative; top: -110px; left: 2rem; margin-bottom: -120px; letter-spacing:4.5px; }
p.body {margin-left: 2rem; max-width:75%; text-align: left; padding: .5rem;}

ul ul {
  margin-bottom:1rem;
}


.App {
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: white;
  width:100vw;
  min-height:100vh;
  display:flex;
  overflow-x:hidden;

  #burgerContainer{
    transition: .3s ease all;
    position: fixed;
    bottom: 1vh;
    left: 2rem;

    @media screen and (max-width: $breakPointLarge) {
      display:none;
    }
    @media screen and (max-width: $breakPointMedium) {
      display:block;
    }
    @media screen and (max-width: $breakPointSmall) {
      display:block;
    }
  }

  div.shown {
    opacity:1;
  }

  div.hidden {
    opacity:0;
  }


  #mainNav{
    transition: .7s ease all;
  }
  #social .socialLinks{
    display: block;
    left: 0;
    width:100%;
  
    margin:1rem;


    a img{
      max-width:44px;
     
      margin-right:.75rem;
      margin-left:.75rem;
      align-self:center;
    }
  }

  .sideBar {
    min-width:260px;
    width: 25vw;
    max-width:375px;
    height:120vh;
    margin-top:-10vh;
    padding-top:10vh;
    background-color: #F4F4F4;
    position:fixed;
    padding-right:2rem;
    transition: .8s ease all;
    left:0;
    z-index:90;

    ul.siteMenu
    {
       text-align: center;
       display: flex;
       flex-direction: column;
       align-items: center;

      li {line-height:1.5rem; color:$accent; list-style:none; margin-left: 0; font-size: 1.2rem; font-weight:300;  padding:1rem; cursor: pointer; transition: .6s ease all;  border-radius:0px;}
      li:hover {color:$lightBg;  }
      li#selected a{font-weight:500; color:$accent; font-size:1.2rem; }
      li#secretCode input{    padding: .7rem;
        border-radius: 50px;
        border: 3px solid #D4D4D4;
        font-size: 1.3rem;
        background-color: transparent;
        color: $darkTxt;
        text-align: center;
        display:none;
      }
      li a {color: $darkTxt; text-decoration:none; font-weight:500; transition: all .3s ease-in-out;}
      li a:hover {font-weight:500; color:$accent; font-size:1.2rem;}
    }
    li#secretCode:hover {
      color:$lightBg; list-style:none; margin-left: 0; font-size: 1.2rem; border-bottom:1px solid rgba(255,255,255,.2); padding:1rem; cursor: pointer;
      width:auto;
    }
  }
  .sideBar.off {
      @media screen and (max-width: $breakPointLarge) {
        left: 0px;
      }
      @media screen and (max-width: $breakPointMedium) {
        left: -375px;
      }
  }
  .burger {
    cursor: pointer;
    img{width:92px;}
  }

  .content{
    width:620px;
    margin-left:30vw;
    overflow-x:hidden;
    padding-bottom:30vh;

  
    @media screen and (max-width: $breakPointLarge) {
      margin-left:30vw;
      max-width:768vw;
    }
    @media screen and (max-width: $breakPointMedium) {
      margin-left:0vw;
      width:96vw;
    }
    @media screen and (max-width: $breakPointSmall) {
      width:90vw;
      margin-left:.75rem;
    }
    
    background-color: white;
    min-height: 100vh;
    width: 70vw;


    img.portfolioImage {
      float:none;
      text-align:left;
      display:block;

      @media screen and (max-width: $breakPointLarge) {
        width:70vw;
      }
      @media screen and (max-width: $breakPointMedium) {
        margin-left:0vw;
        max-width:550px;
      }
      @media screen and (max-width: $breakPointSmall) {
        width:100vw;
      }
      
    }
    
    ul.projectList {
      max-width:768px;
      padding-left:.75rem;
      margin-top:0px;

      li.projectTitle {
        
        font-size:1.75rem;
        line-height:2.25rem;
        font-family: 'Bitter';
        font: weight 400;
        text-align:left;
        list-style: none;
        max-width:668px;
        color: #1c1c1c;
        margin-bottom:1rem;
        margin-top:1rem;
        margin-left:.5rem;
       
      }

      li.footerTitle {
        font-size:1.5rem;
        font-family: 'Abril Fatface', cursive;
        text-align:left;
        list-style: none;
      
      }

      li.projectDescription {
        font-size:1.125rem;
        list-style:none;
        text-align:left;
        color:#3A3A3A;
        max-width:668px;
        margin-bottom: 1.5rem;
        margin-top: .5rem;
        margin-left:.5rem;
        line-height:1.6rem;
      }
      li.projectSeparator {
        font-size:1rem;
        list-style:none;
        text-align:left;
        color:white;
        border-radius:50px;
        height:1px;
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-left:.5rem;
        width:30%;
       
        background-color:$accent;
        @media screen and (max-width: $breakPointLarge) {
          width:30%;
        }
        @media screen and (max-width: $breakPointMedium) {
          width:30%;
        }
        @media screen and (max-width: $breakPointSmall) {
          width:100%;
        }
      }

      li.projectImage {
        font-size:1rem;
        list-style:none;
        max-width:1000px;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: left;
       
        img { 
         width:100%;
        
        
        }
      }

      li.footerImage {
       display:block;
      }

    }

  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
